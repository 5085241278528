::-webkit-scrollbar {
  display: none;
}

#skillProficieny {
  display: grid;
  align-content: center;
}

#statShort {
  font-family: monospace;
  align-content: center;
  text-align: center;
}

#statValue {
  font-size: xxx-large;
  line-height: normal;
}

#skillModifier {
  display: grid;
  align-content: center;
  text-align: right;
}

#editor {
  border: solid 1px rgba(255, 255, 255, 0.12);
  border-radius: 4px;
  padding: 8px;
  outline: none;
}

#editor p:first-child {
  margin-top: 0;
}

#editor p:last-child {
  margin-bottom: 0;
}

#editor:focus-visible, #editor:focus {
  border: solid 1px rgba(255, 255, 255, 0.7);
}

.paragraph {
  white-space: initial;
}

.bold {
  font-weight: 700;
}

.italic {
  font-style: italic
}

.underline {
  text-decoration: underline;
}

.strikethrough {
  text-decoration-line: line-through;
}
